
import { useState, Fragment, useEffect, createRef } from "react";
import {
    Collapse,
    Divider,
    Drawer as MuiDrawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Box,
} from '@mui/material';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { drawerWidth } from "../appbar/appbar";
import { NavLink, useLocation } from 'react-router-dom';
import '../../css/drawer.css';
import {
    DynamicIcon,
} from "../../../libs/helpers";
import {
    ModuleI,
    OptionI,
} from "../../../libs/interfaces";
import { Navigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
    Button as ButtonAntd
} from "antd";
import { useRouterStore } from "../../../libs/stores";
import { COLOR_PRIMARY, COLOR_SECONDARY, NAVBAR_HEIGHT } from "../../../libs/constants";
import { useWidth } from "../../../libs/hooks";

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'center',
    height: `${NAVBAR_HEIGHT}px`,
    minHeight: `${NAVBAR_HEIGHT}px`,
    maxHeight: `${NAVBAR_HEIGHT}px`,
    backgroundColor: COLOR_PRIMARY,
    color: "white",
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(78px + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(78px + 1px)`,
    },
});

const DrawerEl = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        zIndex: 998,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface PropsDrawerI {
    open: boolean;
    handleClose: () => void;
}

interface PropsI {
    data: ModuleI;
    padding?: number;
    open: boolean;
}

interface TokenUserPropsI {
    id: number;
    fullName: string;
    lastName: string;
    firstName: string;
    area: string;
    mail: string;
    type: string;
    token: string;
    modules: Array<ModuleI>;
    profileId: number;
}

interface TokenPropsI {
    user: TokenUserPropsI;

}

export const Drawer = ({ open, handleClose }: PropsDrawerI) => {

    const theme = useTheme();
    const width = useWidth();
    const location = useLocation();
    // const [modules, setModules] = useState<Array<ModuleI>>([]);
    const modules = useRouterStore((state) => state.modules);
    // const setModules = useRouterStore((state) => state.setModules);
    const setOptions = useRouterStore((state) => state.setOptions);
    // const profileId = useRouterStore((state) => state.profileId);

    useEffect(() => {
        handleData()
    }, [])

    const handleData = async () => {
        try {

            handleOptions();

            const getToken: string | null = localStorage.getItem(process.env.REACT_APP_TOKEN!);

            if (!getToken) return <Navigate to="/login" />

            const dataStorage: TokenPropsI = JSON.parse(getToken);

            dataStorage.user.modules = modules!;


            localStorage.setItem(process.env.REACT_APP_TOKEN!, JSON.stringify(dataStorage));



        } catch (err: any) {
            enqueueSnackbar(err!.toString(), { variant: "error" });
        }

    }

    const handleOptions = () => {
        let elPath = location.pathname;

        if (elPath === "/equipments") return;

        let options_: OptionI[] = [];
        if (location.pathname.includes("edit")) {
            const splitLoc = location.pathname.split("/");
            splitLoc.pop();
            splitLoc.pop();
            elPath = splitLoc.join("/")
        } else {
            const splitLoc = location.pathname.split("/");
            if (location.pathname.includes("equipments") && splitLoc.length === 4) {
                splitLoc.pop();
            }
            splitLoc.pop();
            elPath = splitLoc.join("/")
        }

        for (const x of modules) {
            if (x.options) {

                if (elPath === x.to) options_ = x.options

            } else {
                for (const y of x.children!) {
                    if (elPath === y.to) options_ = y.options!
                }
            }
        }

        setOptions(options_);
    }

    const BodyDrawer = () => (
        <Fragment>
            <DrawerHeader>
                <ButtonAntd
                    type="dashed"
                    style={{
                        position:"relative",
                        top:-1,
                    }}
                    onClick={handleClose}
                    icon={theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    size="small"
                />
                <Box
                    sx={{ ml: 1, display: "flex", flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start" }}>
                    <Typography variant="h5" gutterBottom sx={{ m: 0 }} >
                        KOD
                    </Typography>
                    <Typography variant="caption" gutterBottom sx={{ mt: -0.6 }}>
                        Control de Activos
                    </Typography>
                </Box>
            </DrawerHeader>
            <Divider />
            <List sx={{
                p: 0,
                width: width === "xs" ? drawerWidth : "auto",
                overflowY: "auto",
                overflowX: "hidden",
                height: `calc(100vh - ${Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2}px)`,
                minHeight: `calc(100vh - ${Number(width === "xs" ? 130 :NAVBAR_HEIGHT) + 2}px)`,
                maxHeight: `calc(100vh - ${Number(width === "xs" ? 130 :NAVBAR_HEIGHT) + 2}px)`,
            }}>
                {
                    modules.map(el => <DrawerItem key={`module_${el.id}`} data={el} open={open} />)
                }
            </List>
        </Fragment>
    )

    if (width === "xs") {
        return (
            <MuiDrawer 
                open={open} 
                onClose={handleClose}
                >
                <BodyDrawer />
            </MuiDrawer>
        )
    }
    return (
        <DrawerEl
            variant="permanent"
            open={open}>
            <BodyDrawer />
        </DrawerEl>
    )
}

export const DrawerItem = ({
    data,
    open
}: PropsI) => {

    const {
        id,
        name,
        nameSort,
        icon,
        iconLibrary,
        iconActive,
        iconActiveLibrary,
        children,
    } = data;

    const [openOption, setOpenOption] = useState(false);

    const onClickOption = () => setOpenOption(state => !state);

    if (!children) {
        return (
            <DrawerItemChildren data={data} padding={0} open={open} />
        )
    }

    if (children.length === 0) {
        return (
            <DrawerItemChildren data={data} padding={0} open={open} />
        )
    }

    return (
        <Fragment>
            <ListItem
                key={`${id}_${name}_${nameSort}`}
                disablePadding
                divider
                sx={{
                    backgroundColor: openOption ? COLOR_SECONDARY : 'white',
                    color: openOption ? 'white' : COLOR_PRIMARY,
                }}
            >
                <ListItemButton
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        height: 55,
                        textDecoration:"none"
                    }}
                    onClick={onClickOption}
                >
                    <ListItemIcon >
                        <DynamicIcon
                            icon={openOption ? iconActive : icon}
                            lib={openOption ? iconActiveLibrary : iconLibrary}
                            color={openOption ? "#fff" : COLOR_PRIMARY}
                            size={open ? 20 : 16}
                        />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        sx={{
                            fontSize: open ? 12 : 10,
                            fontWeight: open ? "bolder" : 600,
                            position: "relative",
                            left: open ? -30 : 0,
                            textDecoration:"none",
                            // transition:"all 0.2s ease-in-out"
                        }}
                        primary={nameSort} />
                </ListItemButton>
            </ListItem>
            <Collapse in={openOption} timeout="auto" unmountOnExit>
                <List component="div" disablePadding >
                    {
                        children.map(el =>
                            <DrawerItemChildren
                                key={`${el.id}_${el.code}`}
                                data={el}
                                padding={0.5}
                                open={open}
                            />)
                    }
                </List>
            </Collapse>
        </Fragment>
    )
}

export const DrawerItemChildren = ({
    data,
    padding,
    open
}: PropsI) => {

    const {
        id,
        to,
        name,
        nameSort,
        icon,
        iconLibrary,
        iconActive,
        iconActiveLibrary,
        options,
        parentModuleId,
    } = data;

    const setOptions = useRouterStore((state) => state.setOptions);

    return (
        <ListItem
            key={`${id}_${name}_${nameSort}`}
            disablePadding
            sx={{
                pl: padding,
                borderBottom: options ? "1px solid gainsboro" : "none",
                background: parentModuleId && options ? "#E5E7E9" : "white",
                height: parentModuleId && options ? 50 : 55,
            }}
            onClick={() => {
                let options_ = options ?? [];
                options_ = options_.map(el => ({ ...el, nodeRef: createRef() }))
                setOptions(options_)
            }}
        >
            <NavLink to={to!} style={{ width: '100%', textDecoration:"none" }}>
                {({ isActive, isPending }) => (
                    <div className={isActive ? 'navlinkA' : 'navlink'}>
                        <ListItemButton
                            className="list-item-button"
                            selected={isActive}
                        >
                            <ListItemIcon>
                                <DynamicIcon
                                    icon={isActive ? iconActive : icon}
                                    lib={isActive ? iconActiveLibrary : iconLibrary}
                                    color={isActive ? "#fff" : COLOR_PRIMARY}
                                    size={open ? 20 : 16}
                                />
                            </ListItemIcon>
                            <ListItemText
                                // className="list-item-text"
                                disableTypography
                                sx={{
                                    fontSize: open ? 12 : 10,
                                    fontWeight: open ? "bolder" : 600,
                                    position: "relative",
                                    left: open ? -30 : 0,
                                    textDecoration:"none",
                                    // transition:"all 0.2s ease-in-out"
                                }}
                                primary={nameSort} />
                        </ListItemButton>
                    </div>
                )}
            </NavLink>
        </ListItem>
    )

}