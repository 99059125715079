import { useEffect, useState } from "react";
// import { Canvas, useFrame, useThree } from "@react-three/fiber";
// import {
//   Text3D,
//   Center,
//   Preload,
//   Lightformer,
//   Environment,
//   CameraControls,
//   RenderTexture,
//   ContactShadows,
//   MeshTransmissionMaterial,
// } from "@react-three/drei";
// import { Physics, RigidBody, CuboidCollider } from "@react-three/rapier";
// import Basic from "./sandboxes/Basic";
// import font from "./bold.blob";
import {
  CWidgetStatsA,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { CIcon } from "@coreui/icons-react";
import { cilOptions } from "@coreui/icons";
import { CChartLine } from "@coreui/react-chartjs";
import { getDataWidgetEquipments, usersGetbyToken } from "../../apis";
import { enqueueSnackbar } from "notistack";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "@coreui/coreui/dist/css/coreui.min.css";
import FondoDashboard from "./assets/bg-dashboard.jpg";
import { useRouterStore } from "../../../libs/stores";
import { OptionI } from "../../../libs/interfaces";

export const Dashboard = () => {
  const navigate = useNavigate();
  const setOptionsFirst = useRouterStore((state) => state.setOptionsFirst);

  const [data, setData] = useState<any>({
    total: 0,
    equipments: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data: dataEquipments } = await getDataWidgetEquipments();
      if (!dataEquipments) return;

      setData({ ...dataEquipments });
      const getDatas_ = localStorage.getItem(
        process.env.REACT_APP_TOKEN_ROUTER!
      );

      const datas_ = JSON.parse(getDatas_!);
      let options_: OptionI[] = datas_.state.modules.find(
        (el:any) => el.to === "/equipments"
      )!.options!;
      setOptionsFirst(options_);
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err.toString(), { variant: "error" });
      console.log(err);
    }
  };

  const handleList = () => {
    navigate("/equipments");
  };

  const handleCrear = () => {
    navigate("/equipments/add");
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundImage: `url(${FondoDashboard})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          bottom: 10,
          left: 10,
          width: 140,
          background: "rgba(0,0,0,0.4)",
          color: "white",
          borderRadius: 2,
          padding: "5px",
        }}
      >
        double click , click, scroll
        <br />a letter to zoom
      </div> */}
      <Box sx={{ position: "absolute", top: 10, left: 10, zIndex: 2 }}>
        <CWidgetStatsA
          style={{
            width: 375,
            backgroundColor: "rgba(72, 61, 139, 0.5)",
            color: "white",
          }}
          // color="secondary"
          value={<>{data.total}</>}
          title="Activos"
          action={
            <CDropdown alignment="end">
              <CDropdownToggle
                color="transparent"
                caret={false}
                className="p-0"
              >
                <CIcon icon={cilOptions} className="text-white" />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem onClick={handleList}>Listar</CDropdownItem>
                <CDropdownItem onClick={handleCrear}>Crear</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          }
          chart={
            <CChartLine
              className="mt-2 mx-3"
              style={{ maxHeight: "220px", height: "220px" }}
              data={{
                labels: data.equipments.map((el: any) => el?.name ?? ""),
                datasets: [
                  {
                    label: "Total",
                    backgroundColor: "transparent",
                    borderColor: "rgba(255,255,255,.55)",
                    pointBackgroundColor: "#5856d6",
                    data: data.equipments.map((el: any) => el?.total ?? 0),
                    hoverBackgroundColor: "red",
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: true,
                scales: {
                  x: {
                    border: {
                      display: false,
                    },
                    grid: {
                      display: false,
                      // drawBorder: false,
                    },
                    ticks: {
                      display: true,
                    },
                  },
                  y: {
                    min: 1,
                    max: data.total,
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: true,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                    tension: 0.4,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 20,
                  },
                },
              }}
            />
          }
        />
      </Box>
      {/* <Box sx={{ zIndex: 1, width: "100%", height: "100%" }}>
        <Words />
      </Box> */}
    </div>
  );
};

// const Words = memo(() => {
//   return (
//     <Canvas
//       dpr={[1.5, 2]}
//       camera={{ position: [-20, 40, 30], fov: 45, near: 1, far: 300 }}
//     >
//       {/** The physics world */}
//       <Physics gravity={[0, -60, 0]}>
//         <Letter char="K" position={[1, 50, -1]} rotation={[0, 0, 0]}>
//           {/** The sandboxes dropped into here have no idea what's going to happen.
//            For all intents and purposes they're just self-contained components.  */}
//           <Basic />
//         </Letter>
//         <Letter char="O" position={[2, 60, -2]} rotation={[4, 5, 6]}>
//           {/* <PingPong scale={5} /> */}
//           <Basic />
//         </Letter>
//         <Letter char="D" position={[3, 70, 2]} rotation={[7, 8, 9]}>
//           {/* <Rocket position={[-1, -1, 0]} scale={0.6} /> */}
//           <Basic />
//         </Letter>
//         {/* <Letter char="D" position={[-1, 80, 3]} rotation={[10, 11, 12]}> */}
//         {/* <Basic scale={3} /> */}
//         {/* <Basic /> */}
//         {/* </Letter> */}
//         {/* <Letter char="R" position={[-2, 90, 2]} rotation={[13, 14, 15]}> */}
//         {/* <Basic /> */}
//         {/* </Letter> */}
//         {/* <Letter char="S" position={[-3, 100, -3]} rotation={[16, 17, 18]} stencilBuffer> */}
//         {/* <Stencil scale={2} /> */}
//         {/* <Basic /> */}
//         {/* </Letter> */}
//         {/** Invisible walls */}
//         <CuboidCollider position={[0, -6, 0]} args={[100, 1, 100]} />
//         <CuboidCollider position={[0, 0, -30]} args={[30, 100, 1]} />
//         <CuboidCollider position={[0, 0, 10]} args={[30, 100, 1]} />
//         <CuboidCollider position={[-30, 0, 0]} args={[1, 100, 30]} />
//         <CuboidCollider position={[30, 0, 0]} args={[1, 100, 30]} />
//       </Physics>
//       {/** Environment (for reflections) */}
//       <Environment
//         files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/dancing_hall_1k.hdr"
//         resolution={1024}
//       >
//         {/** On top of the HDRI we add some rectangular and circular shapes for nicer reflections */}
//         <group rotation={[-Math.PI / 3, 0, 0]}>
//           <Lightformer
//             intensity={4}
//             rotation-x={Math.PI / 2}
//             position={[0, 5, -9]}
//             scale={[10, 10, 1]}
//           />
//           {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
//             <Lightformer
//               key={i}
//               form="circle"
//               intensity={4}
//               rotation={[Math.PI / 2, 0, 0]}
//               position={[x, 4, i * 4]}
//               scale={[4, 1, 1]}
//             />
//           ))}
//           <Lightformer
//             intensity={2}
//             rotation-y={Math.PI / 2}
//             position={[-5, 1, -1]}
//             scale={[50, 2, 1]}
//           />
//           <Lightformer
//             intensity={2}
//             rotation-y={-Math.PI / 2}
//             position={[10, 1, 0]}
//             scale={[50, 2, 1]}
//           />
//         </group>
//       </Environment>
//       {/** Contact shadows for naive soft shadows */}
//       <ContactShadows
//         smooth={false}
//         scale={100}
//         position={[0, -5.05, 0]}
//         blur={0.5}
//         opacity={0.75}
//       />
//       {/** Yomotsu/camera-controls, a better replacement for OrbitControls */}
//       <CameraControls
//         makeDefault
//         dollyToCursor
//         minPolarAngle={0}
//         maxPolarAngle={Math.PI / 2}
//       />
//       {/** Makes sure everything is processed and GPU uploaded before Threejs "sees" it */}
//       <Preload all />
//     </Canvas>
//   );
// });

// function Letter({ char, children, stencilBuffer = false, ...props }: any) {
//   const main = useRef();
//   const contents = useRef();
//   const events = useThree((state) => state.events);
//   const controls = useThree((state) => state.controls);
//   // The letters contents are moved to its whereabouts in world coordinates
//   useFrame(() => (contents!.current as any ).matrix.copy((main!.current as any).matrixWorld as any));
//   return (
//     /** A physics rigid body */
//     <RigidBody restitution={0.1} colliders="cuboid" {...props}>
//       {/** Center each letter */}
//       <Center ref={main as any}>
//         <Text3D
//           bevelEnabled
//           onDoubleClick={(e) => (
//             e.stopPropagation(), (controls as any).fitToBox(main.current, true)
//           )}
//           font={font}
//           smooth={1}
//           scale={0.125}
//           size={80}
//           height={4}
//           curveSegments={10}
//           bevelThickness={10}
//           bevelSize={2}
//           bevelOffset={0}
//           bevelSegments={5}
//         >
//           {char}
//           <MeshTransmissionMaterial
//             clearcoat={1}
//             samples={3}
//             thickness={40}
//             chromaticAberration={0.25}
//             anisotropy={0.4}
//           >
//             {/** Render a portalled scene into the "buffer" attribute of transmission material, which is a texture.
//                  Since we're moving the contents with the letter shape in world space we take the standard event compute. */}
//             <RenderTexture
//               attach="buffer"
//               stencilBuffer={stencilBuffer}
//               width={512}
//               height={512}
//               compute={events.compute as any}
//             >
//               {/** Everything in here is self-contained, behaves like a regular canvas, but we're *in* the texture */}
//               <color attach="background" args={["#4899c9"]} />
//               <group ref={contents as any} matrixAutoUpdate={false}>
//                 {/** Drop the children in here, this is where the sandboxes land. */}
//                 {children}
//               </group>
//               <Preload all />
//             </RenderTexture>
//           </MeshTransmissionMaterial>
//         </Text3D>
//       </Center>
//     </RigidBody>
//   );
// }
