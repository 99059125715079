import React, { useState, useCallback } from 'react';
import {
  AppBar as MuiAppBar,
  Button,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import {
  Add as AddIcon,
  Create as CreateIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  ArrowLeft,
  ArrowCircleLeftSharp,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import '../../css/appbar.css';
import { DialogAdd, DialogChangePass, DialogCloseSession } from '../dialog';

//imagenes
import logoKod from '../../images/logo-kod.png';
import avatarGif from "../../images/avatar.gif";
import { useRouterStore } from '../../../libs/stores';
import { COLOR_PRIMARY, COLOR_SECONDARY, NAVBAR_HEIGHT } from "../../../libs/constants";
import { useWidth } from "../../../libs/hooks";
import { useNavigate } from 'react-router-dom';


export const drawerWidth = 180;

const AppBarElement = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, small }) => ({
  height: `57px`,
  minHeight: `57px`,
  maxHeight: `57px`,
  zIndex: 999,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: small === "true" ? 0 : drawerWidth,
    width: small === "true" ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar = ({
  authTokens,
  handleDrawerOpen,
  open,
}) => {
  const width = useWidth();
  const navigate = useNavigate();
  const [ anchorEl, setAnchorEl] = useState(null);
  const [ openAdd, setOpenAdd ] = useState(false);
  const [ openSession, setOpenSession ] = useState(false);
  const [ openPassNew, setOpenPassNew ] = useState(false);
  const avatar = useRouterStore((state)=> state.avatar);
  
  const openUser = Boolean(anchorEl);

  const handleMenuUser = (event) => {
    if(anchorEl === null) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  const handleCloseUser = () => {
    setAnchorEl(null);
  };

  const handleCloseAdd = useCallback(()=>{
    setOpenAdd(false);
  },[openAdd]);

  const handleClosePassNew = useCallback(()=>{
    setOpenPassNew(false);
  },[openPassNew]);

  const handleCloseSession = useCallback(()=>{
    setOpenSession(false);
  },[openSession]);


  return (
    <React.Fragment>
    <AppBarElement 
      position="fixed" 
      open={open} 
      small={width === "xs" ? "true" : "false"}
      >
      <Toolbar className='toolbar'>
        <div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            borderRadius: 2,
            border:"1px solid gainsboro",
            height: 32,
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Button
          onClick={()=>navigate(-1)}
          size="large"
          color="secondary"
          variant="outlined"
          sx={{
            position: "relative",
            left: open ? "-19px" : width === "xs" ? "-30px" : "-2px",
            marginRight:"-50px",
          }}
        >
          <ArrowCircleLeftSharp/>
        </Button>
        </div>
      
        <img src={logoKod} alt='KOD' className='logo' />
        <div className="avatar-contain">
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuUser}
            color="inherit"
          >
            <Avatar className="avatar" alt={authTokens.user?.firstName ?? ""} src={avatar ?? avatarGif} />
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={openUser}
              onClose={handleCloseUser}
            >

              {/* <MenuItem onClick={() => {
                if (authTokens) {
                  if (authTokens.tipo !== 'EJECUTIVA') {
                    setOpenAdd(true);
                    setAnchorEl(null);
                  }
                  else {
                    alert('No tiene este Acceso');
                    setAnchorEl(null);
                  }
                }
              }}>
                <AddIcon style={{ color: 'navy' }} /> Crear Usuario
              </MenuItem>
              <MenuItem onClick={() => {
                setOpenPassNew(true);
                setAnchorEl(null); }}>
                  <CreateIcon style={{ color: 'Teal' }} /> Cambiar Clave
              </MenuItem> */}
              <MenuItem onClick={() => {
                setOpenSession(true);
                setAnchorEl(null); }}>
                  <CloseIcon style={{ color: 'Red' }} /> Cerrar Sesion
              </MenuItem>

            </Menu>
          </IconButton>
        </div>
      </Toolbar>
    </AppBarElement>
    <DialogCloseSession open={openSession} handleClose={handleCloseSession}/>  
    <DialogChangePass open={openPassNew} handleClose={handleClosePassNew} authTokens={authTokens}/>
    </React.Fragment>

  )
}