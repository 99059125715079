import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Grow,
  Slide,
} from "@mui/material";
import { Key as KeyIcon, Person as PersonIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Button as ButtonAntd, ConfigProvider, Space } from "antd";
import { usersLoginPost } from "../../libs/apis";
import { OptionI, UsersLoginI } from "../../libs/interfaces";
import { useRouterStore } from "../../libs/stores";
import LogoKod from "../images/logo-kod.png";
import FondoLogin from "../images/fondo-kod.jpg";
import "../css/login.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { URL_BACKEND } from "../../libs/constants";
import imgAmazon from "../images/amazon.png";
import { CheckOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";

export const Login = () => {
  const navigate = useNavigate();
  const tokens = localStorage.getItem(process.env.REACT_APP_TOKEN!);
  const setModules = useRouterStore((state) => state.setModules);
  const setProfileId = useRouterStore((state) => state.setProfileId);
  const setAvatar = useRouterStore((state) => state.setAvatar);
  const setOptionsFirst = useRouterStore((state) => state.setOptionsFirst);
  const [isProcessing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showKod, setShowKod] = useState(false);
  const containerRef = React.useRef<HTMLElement>(null);
  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);
  const rootPrefixCls = getPrefixCls();

  useEffect(() => {
    setShowKod(true);
  }, []);

  const formik = useFormik<UsersLoginI>({
    initialValues: {
      user: "",
      password: "",
    },
    validationSchema: Yup.object({
      user: Yup.string().min(2).max(20).required("Usuario requerido"),
      password: Yup.string().min(2).max(20).required("Password requerida"),
    }),
    onSubmit: async (values) => {
      setProcessing(true);
      try {
        const { data: dataUser } = await usersLoginPost(values);
        if (!dataUser) return;

        let avatar = "";
        if (dataUser.user.avatar) avatar = URL_BACKEND + dataUser.user.avatar;
        localStorage.setItem(
          process.env.REACT_APP_TOKEN!,
          JSON.stringify(dataUser)
        );
        enqueueSnackbar(`Bienvenido ${dataUser.user.fullName}`, {
          variant: "success",
        });
        setModules(dataUser.user.modules);
        setProfileId(dataUser.user.profileId);
        setAvatar(avatar);
        let options_: OptionI[] = dataUser.user.modules.find(
          (el) => el.to === "/equipments"
        )!.options!;
        setOptionsFirst(options_);
        // setTimeout(() => {
        //     navigate('/equipments', { replace: true });
        // }, 800);
        setProcessing(false);
      } catch (err: any) {
        setProcessing(false);
        enqueueSnackbar(err ?? "Error, comuníquese con Sistemas", {
          variant: "error",
        });
      }
    },
  });

  const linearGradientButton = css`
    &.${rootPrefixCls}-btn-primary:not([disabled]):not(
        .${rootPrefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: "";
        background: linear-gradient(135deg, #00255e, #4169e1, #87ceeb);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `;

  if (tokens) {
    navigate("/dashboard", { replace: true });
    return;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="login-root"
      style={{
        backgroundImage: `url(${FondoLogin})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
      }}
    >
      <Grid item xl={3} lg={3.5} sm={4} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Box ref={containerRef} sx={{ position: "relative" }}>
            <Grid container justifyContent="center" className="login-sub-root">
              <Grid item xs={12} className="login-cenefa">
                <Grow
                  in={showKod}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(showKod ? { timeout: 500 } : {})}
                >
                  <a href="https://www.kod.solutions" target="_blank">
                    <img
                      src={LogoKod}
                      width="100"
                      height="100"
                      alt="KOD"
                      className="logo-kod"
                    />
                  </a>
                </Grow>
                <Stack direction="column" sx={{ ml: 1 }}>
                  <Grow
                    in={showKod}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(showKod ? { timeout: 1000 } : {})}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      display="block"
                      sx={{ mt: 2, fontWeight: "bolder", color: "gray" }}
                    >
                      KOD
                    </Typography>
                  </Grow>
                  <Slide
                    direction="left"
                    in={showKod}
                    container={containerRef.current}
                    // style={{ transformOrigin: '0 0 0' }}
                    {...(showKod ? { timeout: 1000 } : {})}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      display="block"
                      sx={{ mt: -2.5, color: "gray" }}
                    >
                      Control de Activos
                    </Typography>
                  </Slide>
                </Stack>
              </Grid>
              <Grid item md={8.5} sm={10} xs={11} className="login-texfield">
                <TextField
                  label="User"
                  autoFocus
                  variant="outlined"
                  id="user"
                  name="user"
                  fullWidth
                  value={formik.values.user || ""}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                  helperText={formik.touched.user && formik.errors.user}
                />
              </Grid>
              <Grid item md={8.5} sm={10} xs={11} className="login-texfield">
                <TextField
                  label="Password"
                  variant="outlined"
                  id="password"
                  name="password"
                  fullWidth
                  value={formik.values.password || ""}
                  onChange={formik.handleChange}
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid
                item
                md={8.5}
                sm={10}
                xs={11}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <ConfigProvider
                    button={{
                      className: linearGradientButton,
                    }}
                  >
                    <ButtonAntd
                      type="primary"
                      htmlType="submit"
                      style={{ width: "60%" }}
                      disabled={isProcessing}
                      loading={isProcessing}
                      icon={<CheckOutlined />}
                      size="large"
                    >
                      Login
                    </ButtonAntd>
                  </ConfigProvider>

                  <ButtonAntd
                    style={{ width: "40%" }}
                    type="dashed"
                    icon={<UsergroupAddOutlined />}
                    size="large"
                    onClick={() => {
                      navigate("/exterior/supplier-ots", { replace: true });
                    }}
                  >
                    Proveedor
                  </ButtonAntd>
                </Space.Compact>
              </Grid>
              {/* <Grid item sm={6} xs={4} className="login-button">
                                <Button
                                    fullWidth
                                    size='large'
                                    type="submit"
                                    variant="contained"
                                    disabled={isProcessing}
                                    endIcon={isProcessing ? <CircularProgress size={18} /> : <Check />}
                                >
                                    Proveedores
                                </Button>
                            </Grid> */}
              <Grid
                item
                xs={12}
                sx={{ p: 1, display: "flex", justifyContent: "center" }}
              >
                <img src={imgAmazon} style={{ width: "60%", height: 60 }} />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};
