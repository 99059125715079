import React, { useEffect, useState } from "react";
import { OneClientInvoice } from "../../../interfaces";
import { Input, Select, Space, Button, DatePicker, InputNumber } from "antd";
import {
  Autocomplete,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useWidth } from "../../../hooks";
import { getClientIdByToken } from "../../../apis";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { Option } = Select;
const dateFormat = "YYYY/MM/DD";

interface PropsMaintenance {
  contacts: OneClientInvoice[];
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  id?: number | null | string;
}

export const MantenimientosComponent = ({
  contacts,
  onChange,
  values,
  id,
}: PropsMaintenance) => {
  const width = useWidth();
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    try {
      const { data: dataClient } = await getClientIdByToken();
      if (!dataClient) setAdmin(true);
    } catch (err) {
      console.log(err);
    }
  };
  const selectAfter = (
    <Select
      style={{ width: 100 }}
      value={values.periodOption || ""}
      onChange={(value) => {
        onChange("periodOption", value);
        if (values.lastMaintenanceDate && values.periodValue) {
          const date_ = addTimeToDate(
            values.lastMaintenanceDate,
            Number(values.periodValue),
            value
          );
          onChange("nextMaintenanceDate", date_);
        }
      }}
    >
      <Option value="Mes(es)">Mes(es)</Option>
      <Option value="Año(s)">Año(s)</Option>
    </Select>
  );

  function addTimeToDate(dateStr: string, amount: number, unit: string) {
    const date = new Date(dateStr);

    if (isNaN(date.getTime())) {
      throw new Error(
        "Fecha inválida. Asegúrate de que la fecha esté en formato YYYY-MM-DD."
      );
    }

    if (unit === "Mes(es)") {
      date.setMonth(date.getMonth() + amount);
    } else if (unit === "Año(s)") {
      date.setFullYear(date.getFullYear() + amount);
    } else {
      throw new Error('Unidad no válida. Usa "months" o "years".');
    }
    return date.toISOString().split("T")[0];
  }

  return (
    <Paper elevation={2} sx={{ minHeight: 160, p: 2, position: "relative" }}>
      <Typography
        sx={{ position: "absolute", top: 10, left: 20, color: "darkgray" }}
      >
        Mantenimientos & Contacto
      </Typography>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6}>
          <Space.Compact style={{ width: "100%" }}>
            <Button
              style={{
                minWidth: width === "xs" ? "150px" : "180px",
                width: width === "xs" ? "150px" : "180px",
              }}
            >
              {width === "xs" ? "Últ. Mantenimiento" : "Último Mantenimiento"}
            </Button>
            <DatePicker
              id="lastMaintenanceDate"
              style={{
                minWidth: `calc(100% - ${width === "xs" ? "150px" : "180px"})`,
                width: `calc(100% - ${width === "xs" ? "150px" : "180px"})`,
              }}
              name="lastMaintenanceDate"
              value={values.lastMaintenanceDate ? dayjs(values.lastMaintenanceDate, dateFormat) : ''}
              onChange={(date) => {
                onChange("lastMaintenanceDate", date);
              }}
              format={dateFormat}
            />
          </Space.Compact>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputNumber
            addonBefore={<p style={{ minWidth: 100 }}>Periódo</p>}
            addonAfter={selectAfter}
            style={{ width: "100%" }}
            value={values.periodValue || ""}
            onChange={(val)=>{
              onChange("periodValue", val)
            }}
            name="periodValue"
            id="periodValue"
            placeholder="1"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Space.Compact style={{ width: "100%" }}>
            <Button
              style={{
                minWidth: width === "xs" ? "150px" : "180px",
                width: width === "xs" ? "150px" : "180px",
              }}
            >
              {width === "xs" ? "Próx. Mantenimiento" : "Próximo Mantenimiento"}
            </Button>
            <DatePicker
              id="nextMaintenanceDate"
              style={{
                minWidth: `calc(100% - ${width === "xs" ? "150px" : "180px"})`,
                width: `calc(100% - ${width === "xs" ? "150px" : "180px"})`,
              }}
              name="nextMaintenanceDate"
              value={values.nextMaintenanceDate ? dayjs(values.nextMaintenanceDate, dateFormat) : ''}
              onChange={(date) => {
                onChange("nextMaintenanceDate", date);
              }}
              format={dateFormat}
            />
          </Space.Compact>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Autocomplete
            id="Contacto"
            options={contacts.map((el) => el.fullName!)}
            value={values.clientContact || ""}
            disabled={contacts.length === 0 ? true : false}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                color="info"
                label="Contacto"
                variant="outlined"
              />
            )}
            onChange={(e, value) => {
              if (value) {
                const fill = contacts.find((el) => el.fullName === value);
                onChange("clientContact", value);
                onChange("clientContactId", fill!.id!);
              } else {
                onChange("clientContact", "");
                onChange("clientContactId", null);
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
