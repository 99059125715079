import { useQuery } from "@tanstack/react-query";
import { supplierContactApi } from "./axios";
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { OneClientInvoice, ServiceResponseI } from "../../interfaces";
import { AxiosError } from "axios";

export const getAllSupplierContactbySupplierId = async (id:number) => {
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await supplierContactApi.get<ServiceResponseI<OneClientInvoice[]>>(`/all-by-supplier-id/${id}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
    
}

export const getSupplierContactbyId = async (id:number) => {
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await supplierContactApi.get<ServiceResponseI<OneClientInvoice>>(`/${id}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((err as Error).message)
    }
    
}


