import { createRef, useEffect, useState } from 'react';
import { NavLink, useLocation, useOutlet, useNavigate, Navigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import {
    Box,
    CircularProgress,
    Grid,
    Grow,
    Typography
} from '@mui/material';
import { NavbarSub } from '../../libs/shared';
import {
    usersGetbyToken,
} from '../../libs/apis';
import '../css/global.css';
import { useRouterStore } from '../../libs/stores';
import {
    DynamicIcon,
} from "../../libs/helpers";
import {
    COLOR_PRIMARY,
    NAVBAR_HEIGHT_SUM
} from "../../libs/constants";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../css/app.css";
import { ModuleI } from '../../libs/interfaces';

interface PropsI {
    data: ModuleI;
    padding?: number;
    open: boolean;
}

interface TokenUserPropsI {
    id: number;
    fullName: string;
    lastName: string;
    firstName: string;
    area: string;
    mail: string;
    type: string;
    token: string;
    modules: Array<ModuleI>;
    profileId: number;
}

interface TokenPropsI {
    user: TokenUserPropsI;
}

export const GenericPage = () => {

    const location = useLocation();

    const options = useRouterStore((state) => state.options);
    const currentOutlet = useOutlet();
    const { nodeRef } =
        options.find((route) => route.to === location.pathname) ?? {}

    useEffect(() => {
        handleValidate();
        console.log(location)
        console.log(options)
    }, []);


    const handleValidate = async () => {
        try {

            await usersGetbyToken();

        } catch (err) {
            console.log(err);
            enqueueSnackbar(err!.toString(), { variant: "error" });
        }
    };




    return (
        <div className='root-subnavbar'>
            <NavbarSub >
                <Grid container spacing={1} sx={{overflowX:"auto", flexWrap:"nowrap"}} >
                    {
                        options.filter(el=>el.show).map(el =>
                            <Grid item key={`option_${location.pathname}_${el.id}`}>
                                <NavLink to={el.to} style={{textDecoration:"none"}} >
                                    {({ isActive, isPending }) => (
                                        <button className={isActive ? "bton-active-subnavbar" : "bton-subnavbar"} >
                                            <DynamicIcon
                                                icon={el.icon}
                                                lib={el.iconLibrary}
                                                color={COLOR_PRIMARY}
                                            />
                                            {el.name}
                                        </button>
                                    )}
                                </NavLink>
                            </Grid>
                        )
                    }
                </Grid>
            </NavbarSub>
            <SwitchTransition>
                <CSSTransition
                    key={location.pathname}
                    nodeRef={nodeRef}
                    timeout={200}
                    classNames="page"
                    unmountOnExit
                >
                    <Page currentOutlet={currentOutlet} />
                </CSSTransition>
            </SwitchTransition>
        </div>
    )
}

interface PagePropsI {
    currentOutlet: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;
}

const Page = ({
    currentOutlet,
}: PagePropsI) => {
    const location = useLocation();
    // const navigate = useNavigate();
    const modules = useRouterStore((state) => state.modules);
    const [noAccess, setNoAccess] = useState(false);

    useEffect(() => {
        validateAccess();
    }, []);

    const validateAccess = () => {

        let notFound = true;
        let elPath = location.pathname;


        const splitLoc = location.pathname.split("/");
        if (location.pathname.includes("edit")) {
            splitLoc.pop();
            elPath = splitLoc.join("/")
        }

        if (location.pathname.includes("equipments") && splitLoc.length === 4) {
            splitLoc.pop();
            elPath = splitLoc.join("/")
        }

        for (const x of modules) {
            if (x.options) {
                if (x.options) {
                    for (const z of x.options) {
                        if (elPath === `${x.to}/${z.to}`) notFound = false
                    }
                }
            } else {
                if (x.children) {
                    for (const y of x.children!) {
                         if(y.options){
                        for (const w of y.options!) {
                            if (elPath === `${y.to}/${w.to}`) notFound = false
                        }
                         }
                    }
                }
            }
        }

        if (notFound) {
            setNoAccess(true);
        } else {
            setNoAccess(false);
        }


    }


    return (
        <div className="page">
            {
                noAccess
                    ? <NoAccess />
                    : currentOutlet
            }
        </div>
    )
}

const NoAccess = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [])

    if (!show) return <LoadingCenter />

    return (
        <Box sx={{
            height: `calc(100vh - ${NAVBAR_HEIGHT_SUM})`,
            minHeight: `calc(100vh - ${NAVBAR_HEIGHT_SUM})`,
            maxHeight: `calc(100vh - ${NAVBAR_HEIGHT_SUM})`,
            display: "flex",
            justifyContent: 'center',
            p: 2,
        }}>
            <Grow in={show} >
                <Typography variant="h2" display="block" gutterBottom sx={{ color: "red" }}>
                    ⛔NO TIENE ACCESO ‼️⚠️
                </Typography>
            </Grow>
        </Box>)
}

const LoadingCenter = () => {
    return (
        <Box sx={{
            height: `calc(100vh - ${NAVBAR_HEIGHT_SUM})`,
            minHeight: `calc(100vh - ${NAVBAR_HEIGHT_SUM})`,
            maxHeight: `calc(100vh - ${NAVBAR_HEIGHT_SUM})`,
            display: "flex",
            justifyContent: 'center',
            p: 2,
        }}>
            <CircularProgress size={50} />
        </Box>)
}
